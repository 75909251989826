import { UploadOutlined } from "@ant-design/icons"
import {
  Button,
  Col,
  DatePicker,
  Descriptions,
  DescriptionsProps,
  Divider,
  Flex,
  Form,
  Input,
  message,
  Row,
  Typography,
  Upload,
  UploadProps,
} from "antd"
import dayjs from "dayjs"
import React, { useEffect } from "react"
import { Plus } from "react-flaticons"
import { ContactSearch } from "../../../shared/form/components/contact-search/ContactSearch"
import { DataType, NDFTable } from "../Table/NDFTable"
import "./devisFormContent.scss"

interface Props {
  devisId: number
  onLoad: (loading: boolean) => void
}

const DATE_FORMAT = "DD-MM-YYYY"

const initialData: DataType[] = [
  {
    key: "1",
    description: "Description d'un article",
    quantity: 1,
    unit: "U",
    price: "10",
    discount: "0",
    tax: "0",
    sum: "10",
  },
]

export const DevisFormContent: React.FC<Props> = ({ devisId, onLoad }) => {
  const isEdition = devisId !== -1
  const form = Form.useFormInstance()
  const [lines, setLines] = React.useState<DataType[]>(initialData)

  useEffect(() => {
    setFormData()
  }, [devisId])

  const setFormData = () => {
    onLoad(true)

    if (!isEdition) {
      form.setFieldsValue({
        nom: "",
        prenom: "",
        denomination: "",
        IDEnt: "",
        numTVA: "",
        fonction: "",
        website: "",
        tags: [],
        notes: "",
      })
      onLoad(false)
      return
    }

    // DevisAPI.getDevis(contactId).then((res) => {
    //   setIsContactACompany(res.data.companyType === EContactType.ENTREPRISE)
    //   form.setFieldsValue({
    //     ...res.data,
    //     tags: res.data.tags.map((tag: ITag) => tag.IDTag),
    //     companyType: res.data.companyType,
    //     IDEnt: res.data.enterprise?.entName,
    //   })
    //   onLoad(false)
    // })
  }

  // TODO: Implement the upload logic
  const props: UploadProps = {
    name: "file",
    action: "https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload",
    headers: {
      authorization: "authorization-text",
    },
    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList)
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`)
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`)
      }
    },
  }

  const priceDetails: DescriptionsProps["items"] = [
    {
      label: "Sous-total",
      children: "€ 0,00",
      span: 4,
    },
    {
      label: "TOTAL (€)",
      children: "€ 0,00",
      span: 4,
    },
  ]

  const handleAdd = () => {
    const newData: DataType = {
      key: (lines.length + 1).toString(),
      description: `Edward King ${lines.length}`,
      quantity: 1,
      unit: "U",
      price: "10",
      discount: "0",
      tax: "0",
      sum: "10",
    }
    setLines([...lines, newData])
  }

  return (
    <Row className='devis-form'>
      <Col xs={24} xl={12}>
        <ContactSearch onSelect={(id) => console.log(id)} label='Nom du client' />
      </Col>

      <Divider className='first-divider' />

      <Col xs={24} xl={12}>
        <Form.Item
          label='N° du devis'
          rules={[
            {
              required: true,
              message: "Veuillez renseigner le numéro du devis",
            },
          ]}
        >
          <Input placeholder='INV-00001' />
        </Form.Item>
      </Col>

      <Col xs={24}>
        <Row>
          <Col xs={24} xl={12}>
            <Form.Item
              label='Date du devis'
              rules={[
                {
                  required: true,
                  message: "Veuillez renseigner la date du devis",
                },
              ]}
            >
              <DatePicker defaultValue={dayjs()} format={DATE_FORMAT} />
            </Form.Item>
          </Col>
          <Col xs={24} xl={12}>
            <Form.Item
              label="Date d'expiration"
              className='expiration-date'
              rules={[
                {
                  required: true,
                  message: "Veuillez renseigner la date d'expiration du devis",
                },
              ]}
            >
              <DatePicker defaultValue={dayjs().add(1, "month")} format={DATE_FORMAT} />
            </Form.Item>
          </Col>
        </Row>
      </Col>

      <Col xs={24} xl={12}>
        <Form.Item label='Titre du devis'>
          <Input placeholder='Devis' />
        </Form.Item>
      </Col>

      <Col xs={24} className='devis-lines'>
        <NDFTable data={lines} />
      </Col>
      <Col xs={24} xl={12}>
        <Button className='add-ligne' icon={<Plus size={15} />} onClick={handleAdd}>
          Ajouter une nouvelle ligne
        </Button>
      </Col>
      <Col xs={24} xl={12}>
        <Descriptions
          bordered
          column={{ xs: 1, sm: 2, md: 3, lg: 3, xl: 4, xxl: 4 }}
          items={priceDetails}
        />
      </Col>

      <Divider />

      <Col xs={24}>
        <Row gutter={{ xl: 20 }}>
          <Col xs={24} xl={16}>
            <Form.Item label='Informations pour le client'>
              <Input.TextArea rows={4} cols={2} />
            </Form.Item>
            <Form.Item label='Conditions générales'>
              <Input.TextArea rows={4} />
            </Form.Item>
          </Col>
          <Col xs={24} xl={8}>
            <Flex className='upload-file' justify='flex-start' align='flex-end' vertical>
              <div className='upload'>
                <Upload {...props}>
                  <Button icon={<UploadOutlined />}>Joindre un fichier</Button>
                </Upload>
                <Typography.Text type='secondary'>
                  Formats acceptés: PDF, DOCX, XLSX, JPG, PNG
                  <br />
                  Taille maximale: 5 Mo
                </Typography.Text>
              </div>
            </Flex>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}
