import { ClearOutlined } from '@ant-design/icons'
import { Col, Form, Input, InputNumber, notification, Radio, Row, Select } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import { BaseOptionType } from 'antd/es/select'
import { useEffect, useState } from 'react'
import { ArticleAPI } from '../../../../services/Article.api'
import { EArticleType } from '../../../../types/EArticleType'
import { EContent } from '../../../../types/EContent'
import { IArticleForm } from '../../../../types/IArticleForm'
import { ITag } from '../../../../types/ITag'
import { Label } from '../../../shared/form/components/label/Label'
import { TagSelect } from '../../../shared/form/components/tags/tag-select/TagSelect'

interface Props {
  articleId: number
  onLoad: (loading: boolean) => void
}

export const ArticleFormContent = ({ articleId, onLoad }: Props) => {
  const [saleUnits, setSaleUnits] = useState<BaseOptionType[]>([])
  const [taxes, setTaxes] = useState<BaseOptionType[]>([])

  const form = Form.useFormInstance<IArticleForm>()

  useEffect(() => {
    ArticleAPI.getSaleUnits().then((res) => {
      if (res.ok) {
        setSaleUnits(
          res.data.map((unit) => ({
            value: unit.IDSaleUnit,
            label: `${unit.suName} (${unit.suDisplayName})`,
          })),
        )
      } else {
        notification.error({
          message: 'Erreur',
          description: 'Impossible de récupérer la liste des unités de vente.',
        })
      }
    })
    ArticleAPI.getTaxes().then((res) => {
      if (res.ok) {
        setTaxes(res.data.map((tax) => ({ value: tax.IDTaxe, label: tax.taxeName })))
      } else {
        notification.error({
          message: 'Erreur',
          description: 'Impossible de récupérer la liste des taxes.',
        })
      }
    })
  }, [])

  useEffect(() => {
    setFormData()
  }, [articleId])

  const setFormData = () => {
    onLoad(true)

    if (articleId === -1) {
      form.setFieldsValue({
        artType: EArticleType.MARCHANDISE,
        artName: '',
        artRef: '',
        artDesc: '',
        artPVHT: 0,
        IDTaxe: undefined,
        IDSaleUnit: undefined,
        tags: [],
      })
      onLoad(false)
      return
    }

    ArticleAPI.getArticle(articleId).then((res) => {
      form.setFieldsValue({
        ...res.data,
        tags: res.data.tags.map((tag: ITag) => tag.IDTag),
        IDSaleUnit: res.data.saleUnit.IDSaleUnit,
        IDTaxe: res.data.taxe.IDTaxe,
      })
      onLoad(false)
    })
  }

  return (
    <Col className='article-form'>
      <Row>
        <Col xs={24} xl={12}>
          <Form.Item
            name='artType'
            label={
              <Label
                text='Type'
                tooltip='Sélectionnez si cet article est une marchandise physique ou un service que vous offrez. Si vous ajoutez cet article dans une prestation, vous ne pourrez pas modifier son type.'
              />
            }
            initialValue={EArticleType.SERVICE}
          >
            <Radio.Group>
              <Radio value={EArticleType.MARCHANDISE}>Marchandise</Radio>
              <Radio value={EArticleType.SERVICE}>Service</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 10, xl: 50 }} wrap>
        <Col xs={24} xl={12}>
          <Form.Item name='artName' label='Nom'>
            <Input placeholder='Nom' />
          </Form.Item>
          <Form.Item
            label={
              <Label
                text='Unité'
                tooltip="C'est l'unité de vente de l'article, par exemple kg pour kilogramme, h pour heure, etc."
              />
            }
            name='IDSaleUnit'
            className='unite-select'
          >
            <Select
              placeholder='Sélectionnez une unité de vente'
              notFoundContent={null}
              showSearch
              filterOption={(inputValue, option) => {
                if (option?.label) {
                  return option.label.toString().toLowerCase().includes(inputValue.toLowerCase())
                }
                return false
              }}
              options={saleUnits}
              allowClear={{ clearIcon: <ClearOutlined /> }}
            />
          </Form.Item>

          <Form.Item name='artPVHT' label='Prix de vente'>
            <InputNumber
              addonAfter={'€'}
              parser={(value) => value?.replace(/,/g, '.') as unknown as number}
              precision={2}
            />
          </Form.Item>

          <Form.Item
            label={
              <Label
                text='Taxe'
                tooltip='Ajoutez la taxe sur la vente applicable pour cet article. Cette taxe sera automatiquement appliquée lorsque vous créerez un devis ou une facture.'
              />
            }
            name='IDTaxe'
          >
            <Select
              placeholder='Sélectionnez une taxe'
              notFoundContent={null}
              showSearch
              filterOption={(inputValue, option) => {
                if (option?.taxeName) {
                  return option.taxeName.toString().toLowerCase().includes(inputValue.toLowerCase())
                }
                return false
              }}
              options={taxes}
              allowClear={{ clearIcon: <ClearOutlined /> }}
            />
          </Form.Item>
        </Col>
        <Col xs={24} xl={12}>
          <Form.Item
            name='artRef'
            label={
              <Label
                text='Référence'
                tooltip='La référence est limité à 8 caractères.'
              />
            }
          >
            <Input maxLength={8} showCount />
          </Form.Item>
          <Form.Item name='artDesc' label='Description' className='description-item'>
            <TextArea autoSize={false} rows={3} className='description' />
          </Form.Item>
          <TagSelect module={EContent.ARTICLES} />
        </Col>
      </Row>
    </Col>
  )
}
