import { ClearOutlined } from "@ant-design/icons"
import { Form, notification, Select } from "antd"
import { useEffect, useState } from "react"
import { ContactAPI } from "../../../../../services/Contacts.api"

interface Props {
  onSelect?: (value: number) => void
  contactNameIds?: IContactNameId[]
  label?: string
}

interface IContactNameId {
  value: number
  label: string
}

export const ContactSearch: React.FC<Props> = ({ onSelect, contactNameIds: nameIds, label }) => {
  const [contactNameIds, setContactNameIds] = useState<IContactNameId[]>()

  useEffect(() => {
    if (nameIds) {
      setContactNameIds(nameIds)
      return
    }
    ContactAPI.getAllContacts()
      .then((res) => {
        if (res.ok) {
          const contacts = res.data.map((contact) => ({
            value: contact.IDTiers,
            label: contact.tiersDenomination,
          }))
          setContactNameIds(contacts)
        }
      })
      .catch(() => {
        notification.error({
          message: "Erreur",
          description: "Impossible de récupérer les contacts.",
        })
      })
  }, [nameIds])

  return (
    <Form.Item name='idContact' label={label}>
      <Select
        placeholder='Rechercher'
        notFoundContent={null}
        showSearch
        filterOption={(inputValue, option) => {
          if (option?.label) {
            return option.label.toString().toLowerCase().includes(inputValue.toLowerCase())
          }
          return false
        }}
        options={contactNameIds}
        allowClear={{ clearIcon: <ClearOutlined /> }}
        onSelect={(value: number) => onSelect && onSelect(value)}
      />
    </Form.Item>
  )
}
