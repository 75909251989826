import { Flex, notification, Row, Space, Spin } from "antd"
import { useEffect, useState } from "react"
import { ContactAPI } from "../../../../services/Contacts.api"
import { ILinkedContact } from "../../../../types/ILinkedContact"
import { IPageInfo } from "../../../../types/IPageInfo"
import { NoData } from "../../../shared/form/pages/TablePage/NoData"
import { ButtonDelete } from "../../../shared/utils/components/ButtonDelete/ButtonDelete"
import { ButtonNew } from "../../../shared/utils/components/ButtonNew/ButtonNew"
import { Table } from "../../../shared/utils/components/Table/Table"
import { LinkedContactModal } from "./LinkedContactModal"
import "./linkedContact.scss"

interface Props {
  tiersId: number
}

const COLUMNS = [
  {
    title: "Denomination",
    field: "tiersDenomination",
  },
  {
    title: "Fonction",
    field: "contactFonction",
  },
  {
    title: "Tél.",
    field: "phone",
  },
  {
    title: "Mail",
    field: "email",
  },
  {
    title: "Adresse",
    field: "formattedAddress",
  },
]

export const LinkedContacts = ({ tiersId }: Props) => {
  const [loading, setLoading] = useState(true)
  const [contacts, setContacts] = useState<ILinkedContact[]>([])
  const [columns, setColumns] = useState<any[]>([])
  const [showModal, setShowModal] = useState(false)
  const [selectedLinkedContactId, setSelectedLinkedContactId] = useState<number>()
  const [selectedContactIds, setSelectedContactIds] = useState<number[]>()
  const [description, setDescription] = useState<IPageInfo>()

  useEffect(() => {
    setLoading(true)
    ContactAPI.getLinkedContactDescription()
      .then((result) => {
        setDescription(result.data)
        setTimeout(() => {
          setLoading(false)
        }, 500)
      })
      .catch(() => {
        notification.error({
          message: "Erreur",
          description: "Impossible de récupérer la description des contacts",
        })
      })
  }, [])

  useEffect(() => {
    const mapped = COLUMNS.map((col) => {
      return {
        key: col.field,
        title: col.title,
        dataIndex: col.field,
        width: `${100 / COLUMNS.length}%`,
        sorter: (a: any, b: any) => {
          const strA = String(a[col.field]).trim().toLowerCase()
          const strB = String(b[col.field]).trim().toLowerCase()
          if (strA === "") return 1
          if (strB === "") return -1
          return strA.localeCompare(strB)
        },
      }
    })
    setColumns(mapped ?? [])
  }, [])

  useEffect(() => {
    getLinkedContacts()
  }, [tiersId])

  const getLinkedContacts = () => {
    setLoading(true)
    ContactAPI.getLinkedContacts(tiersId)
      .then((res) => {
        const data = res.data.map((contact) => {
          return {
            ...contact,
            key: contact.IDContact,
          }
        })
        setContacts(data)
      })
      .catch(() => {
        notification.error({
          message: "Erreur",
          description: "Une erreur est survenue lors de la récupération des contacts liés",
        })
      })
      .finally(() => setLoading(false))
  }

  const noLinkedContacts = () => {
    return (
      <NoData
        onNew={() => setShowModal(true)}
        onNewText={description?.btnTxt}
        pageDescription={description?.titre}
        title={"Personnes à contacter"}
        pageObjectives={
          <>
            {description?.ssTitre}
            <br />
            {description?.texte}
          </>
        }
        whatCanBeDone={[]}
      />
    )
  }

  const onSubmit = (contact: any) => {
    if (selectedLinkedContactId) {
      updateLinkedContact(contact)
    } else {
      addLinkedContact(contact)
    }
  }

  const addLinkedContact = (contact: any) => {
    ContactAPI.linkContact(tiersId, contact)
      .catch(() => {
        notification.error({
          message: "Erreur",
          description: "Une erreur est survenue lors de la création du contact lié",
        })
      })
      .finally(() => {
        setSelectedLinkedContactId(undefined)
        setShowModal(false)
        getLinkedContacts()
      })
  }

  const updateLinkedContact = (contact: any) => {
    console.log(contact)
    if (selectedLinkedContactId) {
      ContactAPI.updatedLinkedContact(tiersId, selectedLinkedContactId, contact)
        .catch(() => {
          notification.error({
            message: "Erreur",
            description: "Une erreur est survenue lors de la mise à jour du contact lié",
          })
        })
        .finally(() => {
          setSelectedLinkedContactId(undefined)
          setShowModal(false)
          getLinkedContacts()
        })
    }
  }

  const unLinkContact = () => {
    if (selectedContactIds) {
      const promises = selectedContactIds.map((id) => {
        ContactAPI.unlinkContact(tiersId, id)
      })
      Promise.all(promises)
        .then(() => {
          notification.success({
            message: "Succès",
            description:
              promises?.length === 1
                ? "Le lien avec le contact a été supprimé avec succès"
                : "Les liens avec les contacts ont été supprimés avec succès",
          })
        })
        .catch(() => {
          notification.error({
            message: "Erreur",
            description:
              promises?.length === 1
                ? "Une erreur est survenue lors de la suppression du lien avec le contact"
                : "Une erreur est survenue lors de la suppression du lien avec les contacts",
          })
        })
        .finally(() => {
          setSelectedContactIds([])
          getLinkedContacts()
        })
    }
  }

  return (
    <div className='linked-contacts'>
      <Spin spinning={loading}>
        <Flex vertical gap={15}>
          <Row justify='end'>
            <Space>
              <ButtonDelete onClick={unLinkContact} size={"small"} iconSize={15} className='trash-button' />
              <ButtonNew onClick={() => setShowModal(true)} size={"small"} withoutIcon />
            </Space>
          </Row>
          <Row className='linked-contacts-table'>
            {contacts.length ? (
              <Table
                loading={loading}
                columnsToDisplay={columns}
                data={contacts}
                noData={() => noLinkedContacts()}
                onEdit={(contact: ILinkedContact) => {
                  setSelectedLinkedContactId(contact.IDContact)
                  setShowModal(true)
                }}
                pagination={false}
                onRowSelection={(selectedRowKeys) => {
                  setSelectedContactIds(selectedRowKeys as number[])
                }}
              />
            ) : (
              noLinkedContacts()
            )}
          </Row>
        </Flex>
      </Spin>

      <LinkedContactModal
        open={showModal}
        linkedContactId={selectedLinkedContactId}
        idTiers={tiersId}
        onClose={() => {
          setShowModal(false)
          setSelectedLinkedContactId(undefined)
        }}
        onOk={onSubmit}
      />
    </div>
  )
}
