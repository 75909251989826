import { HolderOutlined } from "@ant-design/icons"
import { DndContext, DragEndEvent } from "@dnd-kit/core"
import { SyntheticListenerMap } from "@dnd-kit/core/dist/hooks/utilities"
import { restrictToVerticalAxis } from "@dnd-kit/modifiers"
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import { Button, Form, Input, InputRef, Popconfirm, Select, Table } from "antd"
import React, { useContext, useEffect, useMemo, useRef, useState } from "react"
import { ArticleAPI } from "../../../../services/Article.api"

interface Props {
  data: DataType[]
}

export const NDFTable: React.FC<Props> = ({ data }) => {
  const [lines, setLines] = useState<DataType[]>([])
  const [taxes, setTaxes] = useState<{ value: number; label: string }[]>([])
  const [units, setUnits] = useState<{ value: number; label: string }[]>([])

  useEffect(() => {
    ArticleAPI.getTaxes().then((res) => {
      if (res.ok) {
        setTaxes(res.data.map((tax) => ({ value: tax.IDTaxe, label: tax.taxeName })))
      }
    })
    ArticleAPI.getSaleUnits().then((res) => {
      if (res.ok) {
        setUnits(res.data.map((unit) => ({ value: unit.IDSaleUnit, label: unit.suDisplayName })))
      }
    })
  }, [])

  useEffect(() => {
    setLines(data)
  }, [data])

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      setLines((prevState) => {
        const activeIndex = prevState.findIndex((record) => record.key === active?.id)
        const overIndex = prevState.findIndex((record) => record.key === over?.id)
        return arrayMove(prevState, activeIndex, overIndex)
      })
    }
  }

  const handleDelete = (key: React.Key) => {
    const newData = lines.filter((item) => item.key !== key)
    setLines(newData)
  }

  const onTaxChange = (tax: any) => {
    console.log(tax)
  }

  const onUnitChange = (unit: any) => {
    console.log(unit)
  }

  const onDiscountChange = (discount: any) => {
    console.log(discount)
  }

  // (ColumnTypes[number] & { editable?: boolean; dataIndex: string })[]
  // TableColumnsType<DataType>
  const defaultColumns: (ColumnTypes[number] & { editable?: boolean; dataIndex: string })[] = [
    {
      key: "sort",
      align: "center",
      width: 10,
      render: () => <DragHandle />,
      dataIndex: "sort",
      hidden: lines.length < 2,
    },
    { title: "Détail de l'article", dataIndex: "description", align: "left", editable: true },
    { title: "Quantité", dataIndex: "quantity", align: "right", editable: true },
    {
      title: "Unité",
      dataIndex: "unit",
      align: "right",
      render: (_, record) => (
        <Select
          showSearch
          defaultValue={record}
          placeholder='U'
          optionFilterProp='label'
          onChange={onUnitChange}
          options={units}
        />
      ),
    },
    { title: "Prix Unitaire", dataIndex: "price", align: "right", editable: true },
    {
      title: "Remise",
      dataIndex: "discount",
      align: "right",
      render: (_, record) => (
        <Select
          showSearch
          defaultValue={record.tax}
          placeholder='0%'
          optionFilterProp='label'
          onChange={onDiscountChange}
          options={taxes}
        />
      ),
    },
    {
      title: "Taxe",
      dataIndex: "tax",
      align: "left",
      render: (_, record) => (
        <Select
          showSearch
          defaultValue={record.tax}
          placeholder='TVA 5%'
          optionFilterProp='label'
          onChange={onTaxChange}
          options={taxes}
        />
      ),
    },
    { title: "Montant", dataIndex: "sum", align: "right" },
    {
      title: "operation",
      dataIndex: "operation",
      render: (_, record) =>
        lines.length >= 1 ? (
          <Popconfirm title='Sure to delete?' onConfirm={() => handleDelete(record.key)}>
            <a>Delete</a>
          </Popconfirm>
        ) : null,
    },
  ]

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col
    }
    return {
      ...col,
      onCell: (record: DataType) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    }
  })

  const handleSave = (row: DataType) => {
    const newData = [...lines]
    const index = newData.findIndex((item) => row.key === item.key)
    const item = newData[index]
    newData.splice(index, 1, {
      ...item,
      ...row,
    })
    setLines(newData)
  }

  return (
    <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
      <SortableContext items={lines.map((i) => i.key)} strategy={verticalListSortingStrategy}>
        <Table
          scroll={{ x: 300 }}
          rowKey='key'
          components={{ body: { row: CustomRow, cell: EditableCell } }}
          columns={columns as ColumnTypes}
          dataSource={lines}
          bordered
          pagination={false}
          size='small'
          rowClassName='editable-row'
        />
      </SortableContext>
    </DndContext>
  )
}

export interface DataType {
  key: string
  description: string
  quantity: number
  unit: string
  price: string
  discount: string
  tax: string
  sum: string
}

interface RowContextProps {
  setActivatorNodeRef?: (element: HTMLElement | null) => void
  listeners?: SyntheticListenerMap
}

const RowContext = React.createContext<RowContextProps>({})

const DragHandle: React.FC = () => {
  const { setActivatorNodeRef, listeners } = useContext(RowContext)
  return (
    <Button
      type='text'
      size='small'
      icon={<HolderOutlined />}
      style={{ cursor: "move" }}
      ref={setActivatorNodeRef}
      {...listeners}
    />
  )
}

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  "data-row-key": string
}

const CustomRow: React.FC<RowProps> = (props) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: props["data-row-key"] })

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Translate.toString(transform),
    transition,
    ...(isDragging ? { position: "relative", zIndex: 9999 } : {}),
  }

  const contextValue = useMemo<RowContextProps>(
    () => ({ setActivatorNodeRef, listeners }),
    [setActivatorNodeRef, listeners],
  )

  return (
    <RowContext.Provider value={contextValue}>
      <tr {...props} ref={setNodeRef} style={style} {...attributes} />
    </RowContext.Provider>
  )
}

type EditableTableProps = Parameters<typeof Table>[0]

type ColumnTypes = Exclude<EditableTableProps["columns"], undefined>

interface EditableCellProps {
  title: React.ReactNode
  editable: boolean
  dataIndex: keyof DataType
  record: DataType
  handleSave: (record: DataType) => void
}

const EditableCell: React.FC<React.PropsWithChildren<EditableCellProps>> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false)
  const inputRef = useRef<InputRef>(null)
  const form = Form.useFormInstance()

  useEffect(() => {
    if (editing) {
      inputRef.current?.focus()
    }
  }, [editing])

  const toggleEdit = () => {
    setEditing(!editing)
    form.setFieldsValue({ [dataIndex]: record[dataIndex] })
  }

  const save = async () => {
    try {
      const values = await form.validateFields()

      toggleEdit()
      handleSave({ ...record, ...values })
    } catch (errInfo) {
      console.log("Save failed:", errInfo)
    }
  }

  let childNode = children

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{ margin: 0 }}
        name={dataIndex}
        rules={[{ required: true, message: `${title} is required.` }]}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div
        className='editable-cell-value-wrap'
        style={{ paddingInlineEnd: 24 }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    )
  }

  return <td {...restProps}>{childNode}</td>
}
