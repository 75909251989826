import { EContactType } from "./EContactType"
import { IBasicCompany } from "./IBasicCompany"
import { ITag } from "./ITag"

interface BaseContact {
  IDTiers: number
  companyType: EContactType
  denomination: string
  numTVA: string
  nom: string
  prenom: string
  fonction: string
  adresse: string
  formattedAddress: string
  codePostal: number
  ville: string
  countryCode: string
  countryName: string
  phone: string
  phone2: string
  email: string
  website: string
  notes: string
}

export interface IContactFromAPI extends BaseContact {
  _enterprise: IBasicCompany
  _tags: ITag[]
}

export interface IContact extends BaseContact {
  enterprise: IBasicCompany
  tags: ITag[]
}

export class Contact implements IContact {
  IDTiers: number
  companyType: EContactType
  denomination: string
  numTVA: string
  nom: string
  prenom: string
  fonction: string
  adresse: string
  formattedAddress: string
  codePostal: number
  ville: string
  countryCode: string
  countryName: string
  phone: string
  phone2: string
  email: string
  website: string
  notes: string
  enterprise: IBasicCompany
  tags: ITag[]

  constructor(contact: IContactFromAPI) {
    this.IDTiers = contact.IDTiers
    this.companyType = contact.companyType
    this.denomination = contact.denomination
    this.numTVA = contact.numTVA
    this.nom = contact.nom
    this.prenom = contact.prenom
    this.fonction = contact.fonction
    this.adresse = contact.adresse
    this.formattedAddress = contact.formattedAddress
    this.codePostal = contact.codePostal
    this.ville = contact.ville
    this.countryCode = contact.countryCode
    this.countryName = contact.countryName
    this.phone = contact.phone
    this.phone2 = contact.phone2
    this.email = contact.email
    this.website = contact.website
    this.notes = contact.notes
    this.enterprise = contact._enterprise
    this.tags = contact._tags
  }
}

export interface ISearchContact {
  IDTiers: number
  tiersDenomination: string
  formattedAddress: string
  adresse: string
  codePostal: number
  ville: string
  countryCode: string
  countryName: string
  phone: string
  email: string
  website: string
}
