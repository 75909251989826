import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons"
import Icon from "@ant-design/icons/lib/components/Icon"
import { Layout as AntLayout, Button, Divider, Flex, Image } from "antd"
import Sider from "antd/es/layout/Sider"
import { useState } from "react"
import { Plus } from "react-flaticons"
import { Outlet, useLocation, useNavigate } from "react-router-dom"
import { authRoute } from "../../../../../config/routes"
import { IRawRoute } from "../../../../../services/route.utils"
import { useUser } from "../../../../../stores/User.store"
import { SettingDrawer } from "../../../../settings/settings/components/drawer/SettingDrawer"
import { Footer } from "../Footer/Footer"
import { UserIcon } from "../UserIcon/UserIcon"
import "./layout.scss"

const { Header: AntHeader } = AntLayout

export const Layout: React.FC = () => {
  const [collapsed, setCollapsed] = useState(false)

  const navigate = useNavigate()
  const location = useLocation()
  const user = useUser()

  const [menu, setMenu] = useState<IRawRoute>()
  const [showModal, setShowModal] = useState(false)

  const getNavigationItems = () => {
    let currentGroupId = -1
    return authRoute.map((route, i) => {
      const item = (
        <Flex
          align='center'
          className={`link ${location.pathname.includes(route.path) ? "selected-link" : ""}`}
          onClick={() => navigate(route.path)}
          justify='space-between'
        >
          <Flex gap={10}>
            <Icon component={route.icon} />
            {route.name}
          </Flex>
          {route.Modal && (
            <Button
              className='new-item'
              icon={<Plus size={10} />}
              onClick={(e) => {
                e.stopPropagation()
                setShowModal(true)
                setMenu(route)
              }}
            />
          )}
        </Flex>
      )

      if (route.group !== currentGroupId) {
        currentGroupId = route.group
        return (
          <li key={i}>
            {item}
            <Flex justify='center' className='menu-divider'>
              <Divider />
            </Flex>
          </li>
        )
      }
      return <li key={i}>{item}</li>
    })
  }

  return (
    <AntLayout className='layout'>
      <Sider trigger={null} width={240} collapsible collapsed={collapsed} className=''>
        <div className='logo-container'>
          <Image
            width={collapsed ? 50 : 220}
            src={
              collapsed ? "/assets/logo/favicon.png" : "/assets/logo/logo-ndf-rectangle-texte.png"
            }
            alt='Neelaps logo'
            preview={false}
            className='logo'
          />
        </div>
        <ul className='navbar'>{getNavigationItems()}</ul>
        {menu && menu.Modal && (
          <menu.Modal
            onClose={() => setShowModal(false)}
            onSubmit={() => setShowModal(false)}
            isOpen={showModal}
            id={-1}
          />
        )}
      </Sider>
      <AntLayout className='page'>
        <AntHeader>
          <Flex justify='space-between' align='center'>
            <Button
              icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              onClick={() => setCollapsed(!collapsed)}
            />
            <Flex align='center' gap={15}>
              <SettingDrawer />
              <UserIcon user={user} />
            </Flex>
          </Flex>
        </AntHeader>
        <div className='layout-content'>
          <Outlet />
        </div>

        <Footer />
      </AntLayout>
    </AntLayout>
  )
}
