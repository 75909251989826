import React from "react"
import { Icon } from "react-flaticons"
import { Route } from "react-router-dom"
import IFormProps from "../types/IFormProps"

export const renderRoute = (route: IRoute) => {
  const { component, path } = route

  return <Route key={path} path={`${path}`} Component={component} />
}

export interface IRawRoute {
  name?: string
  icon?: Icon
  component: React.FC
  path: string
  group: number
  Modal?: React.FC<IFormProps>
}

export interface IRoute extends IRawRoute {
  needAuth: boolean
}
