import { NDFFetcher } from "../../../services/Fetcher"
import { UtilAPI } from "../../../services/Utils.api"
import { EContent } from "../../../types/EContent"
import { IResponse } from "../../../types/IResponse"
import { EDevisStatus } from "../models/EDevisStatus"
import { IBasicDevis, IBasicDevisFromAPI } from "../models/IBasicDevis"
import { IDevis } from "../models/IDevis"

const BASE_URI = "/ventes/devis"

export class DevisAPI {
  static getPageContent = async () => {
    return UtilAPI.getPageContent(EContent.DEVIS)
  }

  static getAllDevis = async (): Promise<IResponse<IBasicDevis[]>> => {
    return NDFFetcher.get<IBasicDevisFromAPI[]>(BASE_URI).then((res) => {
      return {
        ...res,
        data: Object.values(res.data).map(
          (devis) =>
            ({
              ...devis,
              devisStatut: new EDevisStatus(devis.devisStatut),
            }) as IBasicDevis,
        ),
      }
    })
  }

  static createDevis = (devis: IDevis) => {
    return NDFFetcher.post(BASE_URI, devis)
  }
}
