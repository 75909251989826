import { SettingFilled } from "@ant-design/icons"
import { Button, Divider, Flex, notification, Tag, Typography } from "antd"
import { ColumnFilterItem } from "antd/es/table/interface"
import { useEffect, useState } from "react"
import { UtilAPI } from "../../../../../services/Utils.api"
import { useUser } from "../../../../../stores/User.store"
import { EContent } from "../../../../../types/EContent"
import { ITableColumns } from "../../../../../types/ITableColumn"
import { ITag } from "../../../../../types/ITag"
import { ButtonNew } from "../../../utils/components/ButtonNew/ButtonNew"
import { Table } from "../../../utils/components/Table/Table"
import { ColumnSelector } from "./ColumnSelector"
import { NoData } from "./NoData"
import { tableFilter } from "./TableFilter"
import "./tablePage.scss"

const { Text, Title } = Typography

interface Props {
  title: string
  icon: JSX.Element
  tableType: EContent

  pageDescription: string
  pageObjectives: string

  onNewText: string
  onNew: () => void
  onEdit: (record: any) => void

  whatCanBeDone: string[]

  data: any[]
  loading: boolean

  columnRules?: { [key: string]: object }
  filters?: {
    [key: string]: {
      filters: ColumnFilterItem[]
      onFilter: (value: string, record: any) => boolean
    }
  }
}

export const TablePage = ({
  title,
  icon,
  tableType,
  pageDescription,
  pageObjectives,
  onNewText,
  onNew,
  onEdit,
  whatCanBeDone,
  data: _data = [],
  loading = false,
  columnRules,
  filters,
}: Props) => {
  const [showSetting, setShowSetting] = useState(false)
  const [columns, setColumns] = useState<ITableColumns>()
  const [columnsToDisplay, setColumnsToDisplay] = useState<any[]>([])
  const [data, setData] = useState(_data)

  useEffect(() => {
    setData(_data)
  }, [_data])

  useEffect(() => {
    if (columns?.userColumns) {
      const mapped = columns?.userColumns
        .sort((c1, c2) => c1.displayOrder - c2.displayOrder)
        .map((col) => {
          const colIsTags = col.field == "_tags"
          return {
            key: col.field.replaceAll("_", ""),
            title: col.title,
            dataIndex: col.field.replaceAll("_", ""),
            width: `${100 / columns?.userColumns?.length}%`,
            ...(filters && filters[col.field]
              ? filters[col.field]
              : tableFilter({
                  colTitle: col.title,
                  columnName: col.field,
                  hideFilter: colIsTags,
                  render: colIsTags
                    ? (tags: ITag[]) => (
                        <div>
                          {tags?.map((tag) => (
                            <Tag key={tag.IDTag} color={tag?.tagClassName}>
                              {tag?.tagName}
                            </Tag>
                          ))}
                        </div>
                      )
                    : undefined,
                })),
            sorter: colIsTags
              ? null
              : (a: any, b: any) => {
                  const strA = String(a[col.field]).trim().toLowerCase()
                  const strB = String(b[col.field]).trim().toLowerCase()

                  if (strA === "") return 1
                  if (strB === "") return -1

                  return strA.localeCompare(strB)
                },
            ...(columnRules ?? {})[col.field],
          }
        })
      setColumnsToDisplay(mapped ?? [])
    }
  }, [columns])

  const user = useUser()

  useEffect(() => {
    UtilAPI.getTableColumns(tableType, user.IDUser).then((res) => {
      if (res.ok) {
        setColumns(res.data)
      }
    })
  }, [])

  const noData = () => {
    return (
      <Flex vertical justify='center' align='center' className='no-data'>
        <Flex vertical align='center' className='description'>
          <Title>{pageDescription}</Title>
          <Text>{pageObjectives}</Text>
        </Flex>
        <div className='actions'>
          <Button type='primary' onClick={onNew}>
            {onNewText}
          </Button>
        </div>
        <Divider />
        <Flex vertical align='center' className='what-can-be-done'>
          <Text>Dans la section {title}, vous pouvez :</Text>
          <ul>
            {whatCanBeDone.map((item, index) => (
              <li key={index}>
                <Text>{item}</Text>
              </li>
            ))}
          </ul>
        </Flex>
      </Flex>
    )
  }

  const onChange = (columnIds: string[]) => {
    let newUserColumns = columns?.allcolumns.filter((col) =>
      columnIds.includes(String(col.IDColumn)),
    )
    newUserColumns = (newUserColumns ?? []).map((col) => ({
      ...col,
      displayOrder: columnIds.indexOf(String(col.IDColumn)) + 1,
    }))

    setColumns(
      (old) =>
        ({
          ...old,
          userColumns: newUserColumns?.sort((c1, c2) => c1.displayOrder - c2.displayOrder),
        }) as ITableColumns,
    )

    setShowSetting(false)
    notification.success({
      message: "Colonnes",
      description: "L'ordre des colonnes à été mis à jour.",
    })
  }

  const header = () => {
    return (
      <Flex align='center' justify='flex-end' className='t-header'>
        <Flex className='actions'>
          <Button type='default' icon={<SettingFilled />} onClick={() => setShowSetting(true)} />
          <ButtonNew onClick={onNew} />
        </Flex>
      </Flex>
    )
  }

  return (
    <div className='table'>
      <Flex align='center' className='title'>
        {icon}
        <Title level={3}>{title}</Title>
      </Flex>
      <div className='content'>
        <Table
          header={header}
          loading={loading}
          data={data}
          columnsToDisplay={columnsToDisplay}
          onEdit={onEdit}
          noData={() => (
            <NoData
              onNew={onNew}
              onNewText={onNewText}
              pageDescription={pageDescription}
              pageObjectives={pageObjectives}
              title={title}
              whatCanBeDone={whatCanBeDone}
            />
          )}
        />
      </div>
      <ColumnSelector
        tableType={tableType}
        open={showSetting}
        onClose={() => setShowSetting(false)}
        columns={columns}
        onChange={onChange}
      />
    </div>
  )
}
